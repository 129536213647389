import store from '../store';
import router from '../router';

// eslint-disable-next-line import/prefer-default-export
export const checkForEmptyKeys = (arrayItem) => {
  const arr = arrayItem.map((el) => Object.values(el));
  const arrWithBoolean = arr.map((item) => item.includes(''));
  return arrWithBoolean.includes(true);
};

export const employMaskPhone = (initValue) => {
  const x = initValue.match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);
  const currValue = `+7 (${x[2]}) ${x[3]}-${x[4]}-${x[5]}`;
  return currValue;
};

// Сообщение пользователю об ошибке
export const ShowErrorToUser = (message) => {
  store.commit('notifications/SAVE_MESSAGE', message);
  router.app.$bvToast.show('danger-toast');
};

// Сообщение пользователю об успешном запросе
export const ShowSuccesToUser = (message) => {
  store.commit('notifications/SAVE_MESSAGE', message);
  router.app.$bvToast.show('success-toast');
};

export const deleteMaskPhone = (initValue) => initValue.replace(/\D/g, '').substring(0, 11);

// Скачивание файлов. Использование: .then(result => createElementForDownload(result, fileName))
export const createElementForDownload = (bytes, fileName) => {
  const elm = document.createElement('a'); // CREATE A LINK ELEMENT IN DOM
  elm.href = URL.createObjectURL(bytes); // SET LINK ELEMENTS CONTENTS
  elm.setAttribute('download',
    fileName); // SET ELEMENT CREATED 'ATTRIBUTE' TO DOWNLOAD. Params are after 'dowload'
  elm.click();
};

// обработка ошибок при запросе на сервер
export const catchFunction = (e) => {
  console.error(e);
  store.commit('notifications/SAVE_MESSAGE', e);
  router.app.$bvToast.show('danger-toast');
};

// Часто используемая конфигурация: processResponse(result);
// Используется для показа сообщений о статусе отправленного запроса при нажатии кнопок действий
// Например: удалить, добавить, сохранить, отправить запрос и т.п.
export const processResponse = ({
  status,
  text
}, messageToUser) => {
  let message;
  if (status === 200 && (!text || !text.type)) {
    if (!text.length) {
      store.commit('notifications/SAVE_MESSAGE', 'Запрос отправлен');
    } else if (typeof text === 'string' && text.length > 0 && text.includes(':') && !JSON.parse(text).Error) {
      store.commit('notifications/SAVE_MESSAGE', messageToUser);
    } else {
      store.commit('notifications/SAVE_MESSAGE', JSON.parse(text).Error);
    }
    router.app.$bvToast.show('success-toast');
    return;
  }
  if (status === 500) {
    store.commit('notifications/SAVE_MESSAGE', JSON.parse(text).Error);
    router.app.$bvToast.show('danger-toast');
    return;
  }
  if (status !== 200 && status !== 401) {
    message = 'Что-то пошло не так... Попробуйте обновить страницу';
    catchFunction(message);
    return;
  }
  if (status === 401) {
    message = 'Пользователь не подключен. Возможно просрочена сессия. Обновите страницу';
    catchFunction(message);
  }
};

export const getServiceName = (serviceList, serviceQuery, baseServiceName) => {
  if (!serviceQuery) return baseServiceName;

  const service = serviceList.find((el) => el.text.toLowerCase().replace(/-/g, ' ') === serviceQuery.replace(/-/g, ' '));

  return service.text;
};
